
$body-color: #9b9ca7;


.user-box {
  margin-top: 20px;
  & + & {
    margin-top: 20px;
    .cards-wrapper {
      margin-left: 0;
      flex-grow: 1;
      max-width: none;
    }
    .today {
      margin-left: 30px;
    }
    .cards-header {
      padding: 20px 30px;
      justify-content: space-between;
    }
  }
}

.card {
  background: radial-gradient(ellipse at top, #3ba27b 2%, #087F5A 80%);
  padding: 20px 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  .title {
    font-size: 16px;
    font-weight: 500;
  }
  .subtitle {
    font-size: 13px;
    line-height: 1.6em;
  }
  & + & {
    margin-left: 20px;
  }
}

.account {
  width: 100%;
  min-height: 160px;
  margin-top: auto;
  flex-grow: 0;
  position: relative;
  transition: 0.3s;
  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-profile {
    margin: auto;
    position: relative;
    text-align: center;
    position: relative;
    canvas {
        border-radius: 50%;
    }
    img {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      object-fit: cover;
      object-position: left;
      border: 3px solid #4255d3;
      padding: 5px;
    }
    .blob {
      position: absolute;
      border-radius: 50%;
      animation: fly 5.8s linear infinite alternate;
      &:nth-child(1) {
        width: 14px;
        height: 14px;
        top: 25px;
        left: -20px;
        background: #d9480f;
        animation-delay: 0.9s;
      }
      &:nth-child(2) {
        width: 18px;
        height: 18px;
        background: #5f3dc4;
        right: -20px;
        top: -20px;
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        width: 12px;
        height: 12px;
        background: #008080;
        right: -35px;
        top: 50%;
        animation-delay: 1.8s;
      }
    }
  }
  &-name {
    margin: 20px 0 10px;
  }
  &-title {
    font-size: 14px;
  }
  &-cash {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 6px;
    padding-top: 16px;
    position: relative;
  }
  &-income {
    font-size: 14px;
  }
  &-iban {
    margin-top: auto;
    font-weight: 500;
  }
}

@keyframes fly {
  40% {
    transform: translate(-6px, -6px);
  }
  60% {
    transform: translate(-12px, -2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}


