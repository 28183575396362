.starButton {
    transition: color 0.2s ease;
}

.starButton:hover:not(:disabled) {
    background-color: transparent !important;
    color: var(--mantine-color-orange-5) !important;
}

.starButton:hover:not(:disabled) svg {
    width: 24px;
    height: 24px;
    transition: all 0.2s ease;
}

.starButton svg {
    width: 20px;
    height: 20px;
    transition: all 0.2s ease;
}

.starButton:disabled {
    opacity: 0.5;
}
